import { UseHeadInput } from '@vueuse/head';
import type { IPage } from '@/types/pages/types';

export function usePageSeoMeta(page: IPage): UseHeadInput {
  let imageMeta: { name: string, content: string }[] = [];

  if (page.seo.image) {
    imageMeta = [
      { name: 'og:image', content: page.seo.image.url },
      { name: 'og:image:secure_url', content: page.seo.image.url },
      { name: 'og:image:type', content: page.seo.image.mime },
      { name: 'og:image:width', content: String(page.seo.image.width) },
      { name: 'og:image:height', content: String(page.seo.image.height) },
    ];
  }
  return {
    title: page.title,
    meta: [
      { name: 'title', content: page.seo.title },
      { name: 'description', content: page.seo.description },

      { name: 'og:title', content: page.seo.title },
      { name: 'og:url', content: page.seo.canonicalURL },
      { name: 'og:description', content: page.seo.description },

      ...imageMeta,
    ],
    link: [
      { rel: 'canonical', href: page.seo.canonicalURL },
    ]
  };
}
